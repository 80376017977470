import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import * as style from "./portfolio-block.module.scss"

const PortfolioBlock = ({ data }) => {
  return (
    <div className={style.portfolio}>
      <span className={"subheader"}>{data.Caption}</span>
      <h2 className={"header"}>{data.Header}</h2>
      {data.projects && 
        <div className={style.projects}>
          {data.projects.map(project => (
            <Link to={`/work/${project.Slug}`} className={style.card}>
              {project.Cover && 
                <GatsbyImage image={getImage(project.Cover.localFile)} alt={project.Cover.alternativeText}/>
              }
              <div className={style.cardDetails}>
                <h4>{project.Name}</h4>
                {/* {project.technologies && 
                  <div className={style.pill}>
                    {project.technologies.map(tech => (
                      <div>{tech.Technology}</div>
                    ))}
                  </div>
                } */}
              </div>
            </Link>
          ))}
        </div>
      }
      {data.Link &&
        <div className={"read-more"}>
          <Link to={data.Link.URL}>{data.Link.Label}</Link>
        </div>
      }
    </div>
  )
}

export default PortfolioBlock