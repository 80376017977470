// extracted by mini-css-extract-plugin
export var alignBaseline = "image-text-block-module--align-baseline---Ig2Q";
export var alignBottom = "image-text-block-module--align-bottom--yCsdG";
export var alignContentAround = "image-text-block-module--align-content-around--pTWhx";
export var alignContentBetween = "image-text-block-module--align-content-between--uTvm5";
export var alignContentCenter = "image-text-block-module--align-content-center--t9HyT";
export var alignContentEnd = "image-text-block-module--align-content-end---7TiJ";
export var alignContentLgAround = "image-text-block-module--align-content-lg-around--dK4y7";
export var alignContentLgBetween = "image-text-block-module--align-content-lg-between--3PhUx";
export var alignContentLgCenter = "image-text-block-module--align-content-lg-center--QxGsZ";
export var alignContentLgEnd = "image-text-block-module--align-content-lg-end--mnzX6";
export var alignContentLgStart = "image-text-block-module--align-content-lg-start--Prvda";
export var alignContentLgStretch = "image-text-block-module--align-content-lg-stretch--b9BXe";
export var alignContentMdAround = "image-text-block-module--align-content-md-around--NLaHf";
export var alignContentMdBetween = "image-text-block-module--align-content-md-between--6G47f";
export var alignContentMdCenter = "image-text-block-module--align-content-md-center--zY0qr";
export var alignContentMdEnd = "image-text-block-module--align-content-md-end--+AVq1";
export var alignContentMdStart = "image-text-block-module--align-content-md-start--Lt4V9";
export var alignContentMdStretch = "image-text-block-module--align-content-md-stretch--esxqg";
export var alignContentSmAround = "image-text-block-module--align-content-sm-around--7T9q-";
export var alignContentSmBetween = "image-text-block-module--align-content-sm-between--r3O6Q";
export var alignContentSmCenter = "image-text-block-module--align-content-sm-center--mkSTN";
export var alignContentSmEnd = "image-text-block-module--align-content-sm-end--B6D+b";
export var alignContentSmStart = "image-text-block-module--align-content-sm-start--3uqNd";
export var alignContentSmStretch = "image-text-block-module--align-content-sm-stretch--FryRf";
export var alignContentStart = "image-text-block-module--align-content-start--mGVAs";
export var alignContentStretch = "image-text-block-module--align-content-stretch--gPOgA";
export var alignContentXlAround = "image-text-block-module--align-content-xl-around--5KG2n";
export var alignContentXlBetween = "image-text-block-module--align-content-xl-between--reLez";
export var alignContentXlCenter = "image-text-block-module--align-content-xl-center--MoPEr";
export var alignContentXlEnd = "image-text-block-module--align-content-xl-end---yxaG";
export var alignContentXlStart = "image-text-block-module--align-content-xl-start--nSsNW";
export var alignContentXlStretch = "image-text-block-module--align-content-xl-stretch--PKRJc";
export var alignContentXxlAround = "image-text-block-module--align-content-xxl-around--g3rj+";
export var alignContentXxlBetween = "image-text-block-module--align-content-xxl-between--Cacxo";
export var alignContentXxlCenter = "image-text-block-module--align-content-xxl-center--Gkn1R";
export var alignContentXxlEnd = "image-text-block-module--align-content-xxl-end--n8YtI";
export var alignContentXxlStart = "image-text-block-module--align-content-xxl-start--OV7LC";
export var alignContentXxlStretch = "image-text-block-module--align-content-xxl-stretch--Hp8PG";
export var alignItemsBaseline = "image-text-block-module--align-items-baseline--VNvCh";
export var alignItemsCenter = "image-text-block-module--align-items-center--7VPFr";
export var alignItemsEnd = "image-text-block-module--align-items-end--A9RtX";
export var alignItemsLgBaseline = "image-text-block-module--align-items-lg-baseline--FUtEz";
export var alignItemsLgCenter = "image-text-block-module--align-items-lg-center--mnCvK";
export var alignItemsLgEnd = "image-text-block-module--align-items-lg-end--rPa2d";
export var alignItemsLgStart = "image-text-block-module--align-items-lg-start---Rtp0";
export var alignItemsLgStretch = "image-text-block-module--align-items-lg-stretch--uArOr";
export var alignItemsMdBaseline = "image-text-block-module--align-items-md-baseline--SiJrk";
export var alignItemsMdCenter = "image-text-block-module--align-items-md-center--jJL32";
export var alignItemsMdEnd = "image-text-block-module--align-items-md-end--1KQQZ";
export var alignItemsMdStart = "image-text-block-module--align-items-md-start--+QhJG";
export var alignItemsMdStretch = "image-text-block-module--align-items-md-stretch--I1JlP";
export var alignItemsSmBaseline = "image-text-block-module--align-items-sm-baseline--EBWoc";
export var alignItemsSmCenter = "image-text-block-module--align-items-sm-center--GFwFh";
export var alignItemsSmEnd = "image-text-block-module--align-items-sm-end--dRWwA";
export var alignItemsSmStart = "image-text-block-module--align-items-sm-start--l7zkf";
export var alignItemsSmStretch = "image-text-block-module--align-items-sm-stretch--9ZsuM";
export var alignItemsStart = "image-text-block-module--align-items-start--pkT02";
export var alignItemsStretch = "image-text-block-module--align-items-stretch--RTSTi";
export var alignItemsXlBaseline = "image-text-block-module--align-items-xl-baseline--t+MBt";
export var alignItemsXlCenter = "image-text-block-module--align-items-xl-center--YcSer";
export var alignItemsXlEnd = "image-text-block-module--align-items-xl-end--al2Yc";
export var alignItemsXlStart = "image-text-block-module--align-items-xl-start--vP-YM";
export var alignItemsXlStretch = "image-text-block-module--align-items-xl-stretch--WHw7C";
export var alignItemsXxlBaseline = "image-text-block-module--align-items-xxl-baseline--MbUBU";
export var alignItemsXxlCenter = "image-text-block-module--align-items-xxl-center--HhIVl";
export var alignItemsXxlEnd = "image-text-block-module--align-items-xxl-end--L0uj6";
export var alignItemsXxlStart = "image-text-block-module--align-items-xxl-start---GyNp";
export var alignItemsXxlStretch = "image-text-block-module--align-items-xxl-stretch--kdcY7";
export var alignMiddle = "image-text-block-module--align-middle--Jjn2h";
export var alignSelfAuto = "image-text-block-module--align-self-auto--Rdszn";
export var alignSelfBaseline = "image-text-block-module--align-self-baseline--Heob4";
export var alignSelfCenter = "image-text-block-module--align-self-center--6ficK";
export var alignSelfEnd = "image-text-block-module--align-self-end--Z0ZIr";
export var alignSelfLgAuto = "image-text-block-module--align-self-lg-auto--qaYJE";
export var alignSelfLgBaseline = "image-text-block-module--align-self-lg-baseline--NM-ti";
export var alignSelfLgCenter = "image-text-block-module--align-self-lg-center--a5ADe";
export var alignSelfLgEnd = "image-text-block-module--align-self-lg-end--dPOy5";
export var alignSelfLgStart = "image-text-block-module--align-self-lg-start--nexJp";
export var alignSelfLgStretch = "image-text-block-module--align-self-lg-stretch--nb7OW";
export var alignSelfMdAuto = "image-text-block-module--align-self-md-auto--SGHRi";
export var alignSelfMdBaseline = "image-text-block-module--align-self-md-baseline--4yV6d";
export var alignSelfMdCenter = "image-text-block-module--align-self-md-center--ozZEq";
export var alignSelfMdEnd = "image-text-block-module--align-self-md-end--Kw-9y";
export var alignSelfMdStart = "image-text-block-module--align-self-md-start--yGeP9";
export var alignSelfMdStretch = "image-text-block-module--align-self-md-stretch--HtiPS";
export var alignSelfSmAuto = "image-text-block-module--align-self-sm-auto--a6LMq";
export var alignSelfSmBaseline = "image-text-block-module--align-self-sm-baseline--kuE-K";
export var alignSelfSmCenter = "image-text-block-module--align-self-sm-center--jr5ut";
export var alignSelfSmEnd = "image-text-block-module--align-self-sm-end--RqU4h";
export var alignSelfSmStart = "image-text-block-module--align-self-sm-start--lczSJ";
export var alignSelfSmStretch = "image-text-block-module--align-self-sm-stretch--stneq";
export var alignSelfStart = "image-text-block-module--align-self-start--ROHBb";
export var alignSelfStretch = "image-text-block-module--align-self-stretch--VFMmN";
export var alignSelfXlAuto = "image-text-block-module--align-self-xl-auto--81sOo";
export var alignSelfXlBaseline = "image-text-block-module--align-self-xl-baseline--WaMl7";
export var alignSelfXlCenter = "image-text-block-module--align-self-xl-center--ev+Fq";
export var alignSelfXlEnd = "image-text-block-module--align-self-xl-end--J5f2U";
export var alignSelfXlStart = "image-text-block-module--align-self-xl-start--a17UY";
export var alignSelfXlStretch = "image-text-block-module--align-self-xl-stretch--5dYO0";
export var alignSelfXxlAuto = "image-text-block-module--align-self-xxl-auto--2QU0G";
export var alignSelfXxlBaseline = "image-text-block-module--align-self-xxl-baseline--akfmL";
export var alignSelfXxlCenter = "image-text-block-module--align-self-xxl-center--n8Utt";
export var alignSelfXxlEnd = "image-text-block-module--align-self-xxl-end--Xwqt8";
export var alignSelfXxlStart = "image-text-block-module--align-self-xxl-start--WBiwN";
export var alignSelfXxlStretch = "image-text-block-module--align-self-xxl-stretch--vjHtU";
export var alignTextBottom = "image-text-block-module--align-text-bottom--2Qqfk";
export var alignTextTop = "image-text-block-module--align-text-top--2ugo3";
export var alignTop = "image-text-block-module--align-top--2cNzi";
export var bgBlack = "image-text-block-module--bg-black--BSw++";
export var bgBody = "image-text-block-module--bg-body--7QsiB";
export var bgDanger = "image-text-block-module--bg-danger--4HczG";
export var bgDark = "image-text-block-module--bg-dark--3V588";
export var bgGradient = "image-text-block-module--bg-gradient--Qmrmn";
export var bgInfo = "image-text-block-module--bg-info--WbXjT";
export var bgLight = "image-text-block-module--bg-light--E92i3";
export var bgOpacity10 = "image-text-block-module--bg-opacity-10--4Hjdr";
export var bgOpacity100 = "image-text-block-module--bg-opacity-100--peGQF";
export var bgOpacity25 = "image-text-block-module--bg-opacity-25--MhqEQ";
export var bgOpacity50 = "image-text-block-module--bg-opacity-50--FuOnP";
export var bgOpacity75 = "image-text-block-module--bg-opacity-75--L4YoG";
export var bgPrimary = "image-text-block-module--bg-primary--pPDMz";
export var bgSecondary = "image-text-block-module--bg-secondary--i7NTO";
export var bgSuccess = "image-text-block-module--bg-success--eewfZ";
export var bgTransparent = "image-text-block-module--bg-transparent--Uuj88";
export var bgWarning = "image-text-block-module--bg-warning--Hoj2A";
export var bgWhite = "image-text-block-module--bg-white--ltvvi";
export var block = "image-text-block-module--block--To1Qz";
export var border = "image-text-block-module--border--1cxca";
export var border0 = "image-text-block-module--border-0--MyRrT";
export var border1 = "image-text-block-module--border-1--LW5TV";
export var border2 = "image-text-block-module--border-2--oclYg";
export var border3 = "image-text-block-module--border-3--aPGSX";
export var border4 = "image-text-block-module--border-4--3iB4M";
export var border5 = "image-text-block-module--border-5--aWVLD";
export var borderBottom = "image-text-block-module--border-bottom--JJq-R";
export var borderBottom0 = "image-text-block-module--border-bottom-0--0YZjj";
export var borderDanger = "image-text-block-module--border-danger--NDJe9";
export var borderDark = "image-text-block-module--border-dark--3j0m5";
export var borderEnd = "image-text-block-module--border-end--N+uKs";
export var borderEnd0 = "image-text-block-module--border-end-0--wNuCC";
export var borderInfo = "image-text-block-module--border-info--xjD0V";
export var borderLight = "image-text-block-module--border-light--sEI0w";
export var borderPrimary = "image-text-block-module--border-primary--pl6Dm";
export var borderSecondary = "image-text-block-module--border-secondary--IBIqk";
export var borderStart = "image-text-block-module--border-start--L+Hzr";
export var borderStart0 = "image-text-block-module--border-start-0--vwI+M";
export var borderSuccess = "image-text-block-module--border-success--YJGae";
export var borderTop = "image-text-block-module--border-top--WJ2Fy";
export var borderTop0 = "image-text-block-module--border-top-0--WzNxV";
export var borderWarning = "image-text-block-module--border-warning--GiEsm";
export var borderWhite = "image-text-block-module--border-white--Ldofj";
export var bottom0 = "image-text-block-module--bottom-0--pEM-5";
export var bottom100 = "image-text-block-module--bottom-100--xbA6-";
export var bottom50 = "image-text-block-module--bottom-50--FTOCW";
export var clearfix = "image-text-block-module--clearfix--yw7wc";
export var dBlock = "image-text-block-module--d-block--Hsjqm";
export var dFlex = "image-text-block-module--d-flex--ss9gW";
export var dGrid = "image-text-block-module--d-grid--rYMBy";
export var dInline = "image-text-block-module--d-inline--Xhi3M";
export var dInlineBlock = "image-text-block-module--d-inline-block--f3+sK";
export var dInlineFlex = "image-text-block-module--d-inline-flex--8ByLR";
export var dLgBlock = "image-text-block-module--d-lg-block--NE8Dp";
export var dLgFlex = "image-text-block-module--d-lg-flex--JjMDr";
export var dLgGrid = "image-text-block-module--d-lg-grid--kz1eb";
export var dLgInline = "image-text-block-module--d-lg-inline--2jlD3";
export var dLgInlineBlock = "image-text-block-module--d-lg-inline-block--cikds";
export var dLgInlineFlex = "image-text-block-module--d-lg-inline-flex--0nu1f";
export var dLgNone = "image-text-block-module--d-lg-none--2ExsL";
export var dLgTable = "image-text-block-module--d-lg-table--njxoi";
export var dLgTableCell = "image-text-block-module--d-lg-table-cell--8KvqQ";
export var dLgTableRow = "image-text-block-module--d-lg-table-row--cQx6Q";
export var dMdBlock = "image-text-block-module--d-md-block--BXCEe";
export var dMdFlex = "image-text-block-module--d-md-flex--A5Mtq";
export var dMdGrid = "image-text-block-module--d-md-grid--m+nmZ";
export var dMdInline = "image-text-block-module--d-md-inline--9vtYe";
export var dMdInlineBlock = "image-text-block-module--d-md-inline-block--6Zksi";
export var dMdInlineFlex = "image-text-block-module--d-md-inline-flex--v59KM";
export var dMdNone = "image-text-block-module--d-md-none--G7KrM";
export var dMdTable = "image-text-block-module--d-md-table--0LbTF";
export var dMdTableCell = "image-text-block-module--d-md-table-cell--qfOa9";
export var dMdTableRow = "image-text-block-module--d-md-table-row--rgSfR";
export var dNone = "image-text-block-module--d-none--Fw6Jc";
export var dPrintBlock = "image-text-block-module--d-print-block--L2k+-";
export var dPrintFlex = "image-text-block-module--d-print-flex--MI6NT";
export var dPrintGrid = "image-text-block-module--d-print-grid--YdoOM";
export var dPrintInline = "image-text-block-module--d-print-inline--Q2g5n";
export var dPrintInlineBlock = "image-text-block-module--d-print-inline-block--fZqJ8";
export var dPrintInlineFlex = "image-text-block-module--d-print-inline-flex--zxeN4";
export var dPrintNone = "image-text-block-module--d-print-none--66IkX";
export var dPrintTable = "image-text-block-module--d-print-table--cEX8C";
export var dPrintTableCell = "image-text-block-module--d-print-table-cell--2ytTQ";
export var dPrintTableRow = "image-text-block-module--d-print-table-row--A-g1Y";
export var dSmBlock = "image-text-block-module--d-sm-block--Oic6x";
export var dSmFlex = "image-text-block-module--d-sm-flex--0J2qI";
export var dSmGrid = "image-text-block-module--d-sm-grid--s3oGn";
export var dSmInline = "image-text-block-module--d-sm-inline--TsPlz";
export var dSmInlineBlock = "image-text-block-module--d-sm-inline-block--h8Xit";
export var dSmInlineFlex = "image-text-block-module--d-sm-inline-flex--YKNpK";
export var dSmNone = "image-text-block-module--d-sm-none--SyEQP";
export var dSmTable = "image-text-block-module--d-sm-table--j4akM";
export var dSmTableCell = "image-text-block-module--d-sm-table-cell--eZwPw";
export var dSmTableRow = "image-text-block-module--d-sm-table-row--ylIbz";
export var dTable = "image-text-block-module--d-table--fAqEc";
export var dTableCell = "image-text-block-module--d-table-cell--qD6QT";
export var dTableRow = "image-text-block-module--d-table-row--3tObc";
export var dXlBlock = "image-text-block-module--d-xl-block--4JghH";
export var dXlFlex = "image-text-block-module--d-xl-flex--3Sjd+";
export var dXlGrid = "image-text-block-module--d-xl-grid--+sOHL";
export var dXlInline = "image-text-block-module--d-xl-inline--ZmPeG";
export var dXlInlineBlock = "image-text-block-module--d-xl-inline-block--AABB3";
export var dXlInlineFlex = "image-text-block-module--d-xl-inline-flex--BoCsN";
export var dXlNone = "image-text-block-module--d-xl-none--eQRSR";
export var dXlTable = "image-text-block-module--d-xl-table--e7mX5";
export var dXlTableCell = "image-text-block-module--d-xl-table-cell--mNJZ+";
export var dXlTableRow = "image-text-block-module--d-xl-table-row--1q6Ye";
export var dXxlBlock = "image-text-block-module--d-xxl-block--3lEF2";
export var dXxlFlex = "image-text-block-module--d-xxl-flex--GhfZF";
export var dXxlGrid = "image-text-block-module--d-xxl-grid--W5inN";
export var dXxlInline = "image-text-block-module--d-xxl-inline--AN+6U";
export var dXxlInlineBlock = "image-text-block-module--d-xxl-inline-block--IOOnx";
export var dXxlInlineFlex = "image-text-block-module--d-xxl-inline-flex--uop2a";
export var dXxlNone = "image-text-block-module--d-xxl-none--9QzaN";
export var dXxlTable = "image-text-block-module--d-xxl-table--wbqWO";
export var dXxlTableCell = "image-text-block-module--d-xxl-table-cell--ke9XB";
export var dXxlTableRow = "image-text-block-module--d-xxl-table-row--DTT03";
export var end0 = "image-text-block-module--end-0--x3Z5A";
export var end100 = "image-text-block-module--end-100--GGJlo";
export var end50 = "image-text-block-module--end-50--fIn-i";
export var fixedBottom = "image-text-block-module--fixed-bottom--dBAai";
export var fixedTop = "image-text-block-module--fixed-top---YWhJ";
export var flexColumn = "image-text-block-module--flex-column--i4qBW";
export var flexColumnReverse = "image-text-block-module--flex-column-reverse--94ZEm";
export var flexFill = "image-text-block-module--flex-fill--Z3qpk";
export var flexGrow0 = "image-text-block-module--flex-grow-0--27I8D";
export var flexGrow1 = "image-text-block-module--flex-grow-1--X68Iv";
export var flexLgColumn = "image-text-block-module--flex-lg-column--R2CsW";
export var flexLgColumnReverse = "image-text-block-module--flex-lg-column-reverse--jfmtm";
export var flexLgFill = "image-text-block-module--flex-lg-fill--JlL7q";
export var flexLgGrow0 = "image-text-block-module--flex-lg-grow-0--VghKR";
export var flexLgGrow1 = "image-text-block-module--flex-lg-grow-1--ACUsk";
export var flexLgNowrap = "image-text-block-module--flex-lg-nowrap--N5e86";
export var flexLgRow = "image-text-block-module--flex-lg-row--lNprx";
export var flexLgRowReverse = "image-text-block-module--flex-lg-row-reverse--fBAHx";
export var flexLgShrink0 = "image-text-block-module--flex-lg-shrink-0--SuJ99";
export var flexLgShrink1 = "image-text-block-module--flex-lg-shrink-1--8rQ7B";
export var flexLgWrap = "image-text-block-module--flex-lg-wrap--nR2Qz";
export var flexLgWrapReverse = "image-text-block-module--flex-lg-wrap-reverse--yCqrM";
export var flexMdColumn = "image-text-block-module--flex-md-column--Pm-h-";
export var flexMdColumnReverse = "image-text-block-module--flex-md-column-reverse--ONuMI";
export var flexMdFill = "image-text-block-module--flex-md-fill--M+6Lu";
export var flexMdGrow0 = "image-text-block-module--flex-md-grow-0--wPgAn";
export var flexMdGrow1 = "image-text-block-module--flex-md-grow-1--nxd3V";
export var flexMdNowrap = "image-text-block-module--flex-md-nowrap--qPmO6";
export var flexMdRow = "image-text-block-module--flex-md-row--zh-dT";
export var flexMdRowReverse = "image-text-block-module--flex-md-row-reverse--DkxO+";
export var flexMdShrink0 = "image-text-block-module--flex-md-shrink-0---R+Fl";
export var flexMdShrink1 = "image-text-block-module--flex-md-shrink-1--1M+lA";
export var flexMdWrap = "image-text-block-module--flex-md-wrap--GzHcF";
export var flexMdWrapReverse = "image-text-block-module--flex-md-wrap-reverse--TGpQ6";
export var flexNowrap = "image-text-block-module--flex-nowrap--QWAnP";
export var flexRow = "image-text-block-module--flex-row--nP9p7";
export var flexRowReverse = "image-text-block-module--flex-row-reverse--du5wq";
export var flexShrink0 = "image-text-block-module--flex-shrink-0--x-Unt";
export var flexShrink1 = "image-text-block-module--flex-shrink-1--O1Mg8";
export var flexSmColumn = "image-text-block-module--flex-sm-column--Xe69C";
export var flexSmColumnReverse = "image-text-block-module--flex-sm-column-reverse---82s1";
export var flexSmFill = "image-text-block-module--flex-sm-fill--CZwPc";
export var flexSmGrow0 = "image-text-block-module--flex-sm-grow-0--BE1Vz";
export var flexSmGrow1 = "image-text-block-module--flex-sm-grow-1--VKcvb";
export var flexSmNowrap = "image-text-block-module--flex-sm-nowrap--fjsNx";
export var flexSmRow = "image-text-block-module--flex-sm-row--NOPT6";
export var flexSmRowReverse = "image-text-block-module--flex-sm-row-reverse--mW0Fj";
export var flexSmShrink0 = "image-text-block-module--flex-sm-shrink-0--5xNSi";
export var flexSmShrink1 = "image-text-block-module--flex-sm-shrink-1--CSAsy";
export var flexSmWrap = "image-text-block-module--flex-sm-wrap--DZQv9";
export var flexSmWrapReverse = "image-text-block-module--flex-sm-wrap-reverse--q+8Wr";
export var flexWrap = "image-text-block-module--flex-wrap--imt1x";
export var flexWrapReverse = "image-text-block-module--flex-wrap-reverse--IFjDP";
export var flexXlColumn = "image-text-block-module--flex-xl-column--pdKeZ";
export var flexXlColumnReverse = "image-text-block-module--flex-xl-column-reverse--HzTRJ";
export var flexXlFill = "image-text-block-module--flex-xl-fill--+QSjF";
export var flexXlGrow0 = "image-text-block-module--flex-xl-grow-0--sOqiI";
export var flexXlGrow1 = "image-text-block-module--flex-xl-grow-1--RtyUw";
export var flexXlNowrap = "image-text-block-module--flex-xl-nowrap--v9SRM";
export var flexXlRow = "image-text-block-module--flex-xl-row--EmANj";
export var flexXlRowReverse = "image-text-block-module--flex-xl-row-reverse--NHZWW";
export var flexXlShrink0 = "image-text-block-module--flex-xl-shrink-0--6BwDp";
export var flexXlShrink1 = "image-text-block-module--flex-xl-shrink-1--9ello";
export var flexXlWrap = "image-text-block-module--flex-xl-wrap--ls5ZS";
export var flexXlWrapReverse = "image-text-block-module--flex-xl-wrap-reverse--7MxU1";
export var flexXxlColumn = "image-text-block-module--flex-xxl-column--w8Mkj";
export var flexXxlColumnReverse = "image-text-block-module--flex-xxl-column-reverse--Nhinc";
export var flexXxlFill = "image-text-block-module--flex-xxl-fill--CVtFK";
export var flexXxlGrow0 = "image-text-block-module--flex-xxl-grow-0--sB66D";
export var flexXxlGrow1 = "image-text-block-module--flex-xxl-grow-1--4V81L";
export var flexXxlNowrap = "image-text-block-module--flex-xxl-nowrap--nEKqI";
export var flexXxlRow = "image-text-block-module--flex-xxl-row--FxdnG";
export var flexXxlRowReverse = "image-text-block-module--flex-xxl-row-reverse--E+kqU";
export var flexXxlShrink0 = "image-text-block-module--flex-xxl-shrink-0--tCWIX";
export var flexXxlShrink1 = "image-text-block-module--flex-xxl-shrink-1--1+n5D";
export var flexXxlWrap = "image-text-block-module--flex-xxl-wrap--+lwnt";
export var flexXxlWrapReverse = "image-text-block-module--flex-xxl-wrap-reverse--vFd4s";
export var floatEnd = "image-text-block-module--float-end--pCIOM";
export var floatLgEnd = "image-text-block-module--float-lg-end--BSB3y";
export var floatLgNone = "image-text-block-module--float-lg-none--29DFX";
export var floatLgStart = "image-text-block-module--float-lg-start--68AcQ";
export var floatMdEnd = "image-text-block-module--float-md-end--Iejsh";
export var floatMdNone = "image-text-block-module--float-md-none--oDe9C";
export var floatMdStart = "image-text-block-module--float-md-start--klW3S";
export var floatNone = "image-text-block-module--float-none--Dbz3w";
export var floatSmEnd = "image-text-block-module--float-sm-end--xHHim";
export var floatSmNone = "image-text-block-module--float-sm-none--t-cIh";
export var floatSmStart = "image-text-block-module--float-sm-start--8uNsV";
export var floatStart = "image-text-block-module--float-start--z5-Xv";
export var floatXlEnd = "image-text-block-module--float-xl-end--oqh7K";
export var floatXlNone = "image-text-block-module--float-xl-none--3AVGu";
export var floatXlStart = "image-text-block-module--float-xl-start--WQQh8";
export var floatXxlEnd = "image-text-block-module--float-xxl-end--OMAKw";
export var floatXxlNone = "image-text-block-module--float-xxl-none--ZFl2B";
export var floatXxlStart = "image-text-block-module--float-xxl-start--HXkHR";
export var fontMonospace = "image-text-block-module--font-monospace--TJ5T4";
export var fs1 = "image-text-block-module--fs-1---ddzf";
export var fs2 = "image-text-block-module--fs-2--RGdTE";
export var fs3 = "image-text-block-module--fs-3--TnwJr";
export var fs4 = "image-text-block-module--fs-4--xqnv4";
export var fs5 = "image-text-block-module--fs-5--kYL4w";
export var fs6 = "image-text-block-module--fs-6--cG8W3";
export var fstItalic = "image-text-block-module--fst-italic--oPD-+";
export var fstNormal = "image-text-block-module--fst-normal--l5gDZ";
export var fwBold = "image-text-block-module--fw-bold--CHZEK";
export var fwBolder = "image-text-block-module--fw-bolder--uFGti";
export var fwLight = "image-text-block-module--fw-light--Ny5X0";
export var fwLighter = "image-text-block-module--fw-lighter--OiyQB";
export var fwNormal = "image-text-block-module--fw-normal--zq8XW";
export var gap0 = "image-text-block-module--gap-0--Dd+6S";
export var gap1 = "image-text-block-module--gap-1--zu2s9";
export var gap2 = "image-text-block-module--gap-2--K3SCR";
export var gap3 = "image-text-block-module--gap-3--ZthGR";
export var gap4 = "image-text-block-module--gap-4--3KViH";
export var gap5 = "image-text-block-module--gap-5--ToYT1";
export var gapLg0 = "image-text-block-module--gap-lg-0--k2OwT";
export var gapLg1 = "image-text-block-module--gap-lg-1--p3EIi";
export var gapLg2 = "image-text-block-module--gap-lg-2--W3may";
export var gapLg3 = "image-text-block-module--gap-lg-3--mYsRf";
export var gapLg4 = "image-text-block-module--gap-lg-4--p2pO7";
export var gapLg5 = "image-text-block-module--gap-lg-5--hmfhY";
export var gapMd0 = "image-text-block-module--gap-md-0--aVs0F";
export var gapMd1 = "image-text-block-module--gap-md-1--QudPg";
export var gapMd2 = "image-text-block-module--gap-md-2--tZ3ps";
export var gapMd3 = "image-text-block-module--gap-md-3--4byeg";
export var gapMd4 = "image-text-block-module--gap-md-4--yjnPo";
export var gapMd5 = "image-text-block-module--gap-md-5--Ta-rQ";
export var gapSm0 = "image-text-block-module--gap-sm-0--8UTTQ";
export var gapSm1 = "image-text-block-module--gap-sm-1--dooz7";
export var gapSm2 = "image-text-block-module--gap-sm-2--huTOn";
export var gapSm3 = "image-text-block-module--gap-sm-3--bNy9n";
export var gapSm4 = "image-text-block-module--gap-sm-4--sDtgn";
export var gapSm5 = "image-text-block-module--gap-sm-5--TmTag";
export var gapXl0 = "image-text-block-module--gap-xl-0--bEWCT";
export var gapXl1 = "image-text-block-module--gap-xl-1--Sd--s";
export var gapXl2 = "image-text-block-module--gap-xl-2--Pk-yI";
export var gapXl3 = "image-text-block-module--gap-xl-3--4aWtI";
export var gapXl4 = "image-text-block-module--gap-xl-4--Y1PIf";
export var gapXl5 = "image-text-block-module--gap-xl-5--MtY+H";
export var gapXxl0 = "image-text-block-module--gap-xxl-0--751Lv";
export var gapXxl1 = "image-text-block-module--gap-xxl-1--5mxzU";
export var gapXxl2 = "image-text-block-module--gap-xxl-2--m43jj";
export var gapXxl3 = "image-text-block-module--gap-xxl-3--al8f2";
export var gapXxl4 = "image-text-block-module--gap-xxl-4--ZUvGT";
export var gapXxl5 = "image-text-block-module--gap-xxl-5--iH1o2";
export var h100 = "image-text-block-module--h-100--6B4+b";
export var h25 = "image-text-block-module--h-25--jryDp";
export var h50 = "image-text-block-module--h-50--yu0jQ";
export var h75 = "image-text-block-module--h-75--QE8M+";
export var hAuto = "image-text-block-module--h-auto--+86Cc";
export var hstack = "image-text-block-module--hstack--ubS6B";
export var imageColumn = "image-text-block-module--image-column--n+X7B";
export var invisible = "image-text-block-module--invisible--TLczE";
export var justifyContentAround = "image-text-block-module--justify-content-around--A5z0z";
export var justifyContentBetween = "image-text-block-module--justify-content-between--Z4jYr";
export var justifyContentCenter = "image-text-block-module--justify-content-center--3fjJD";
export var justifyContentEnd = "image-text-block-module--justify-content-end--VwAvz";
export var justifyContentEvenly = "image-text-block-module--justify-content-evenly--KoTl1";
export var justifyContentLgAround = "image-text-block-module--justify-content-lg-around--Q6YUG";
export var justifyContentLgBetween = "image-text-block-module--justify-content-lg-between--2JnnA";
export var justifyContentLgCenter = "image-text-block-module--justify-content-lg-center--x7Qcc";
export var justifyContentLgEnd = "image-text-block-module--justify-content-lg-end--Ef0ma";
export var justifyContentLgEvenly = "image-text-block-module--justify-content-lg-evenly--1y3wa";
export var justifyContentLgStart = "image-text-block-module--justify-content-lg-start--dd8Ju";
export var justifyContentMdAround = "image-text-block-module--justify-content-md-around--dwvPV";
export var justifyContentMdBetween = "image-text-block-module--justify-content-md-between--0Exgb";
export var justifyContentMdCenter = "image-text-block-module--justify-content-md-center--StUiR";
export var justifyContentMdEnd = "image-text-block-module--justify-content-md-end--U9zDA";
export var justifyContentMdEvenly = "image-text-block-module--justify-content-md-evenly--f6-ig";
export var justifyContentMdStart = "image-text-block-module--justify-content-md-start--3hQcj";
export var justifyContentSmAround = "image-text-block-module--justify-content-sm-around--lv4oY";
export var justifyContentSmBetween = "image-text-block-module--justify-content-sm-between--raEVH";
export var justifyContentSmCenter = "image-text-block-module--justify-content-sm-center--+lALW";
export var justifyContentSmEnd = "image-text-block-module--justify-content-sm-end--ULMmL";
export var justifyContentSmEvenly = "image-text-block-module--justify-content-sm-evenly--K5Q71";
export var justifyContentSmStart = "image-text-block-module--justify-content-sm-start--6Ah5p";
export var justifyContentStart = "image-text-block-module--justify-content-start--fwfwP";
export var justifyContentXlAround = "image-text-block-module--justify-content-xl-around--P4Arq";
export var justifyContentXlBetween = "image-text-block-module--justify-content-xl-between--kGMvW";
export var justifyContentXlCenter = "image-text-block-module--justify-content-xl-center--OrgF8";
export var justifyContentXlEnd = "image-text-block-module--justify-content-xl-end--48Ij7";
export var justifyContentXlEvenly = "image-text-block-module--justify-content-xl-evenly--gP3Sv";
export var justifyContentXlStart = "image-text-block-module--justify-content-xl-start--8NaNc";
export var justifyContentXxlAround = "image-text-block-module--justify-content-xxl-around--EKdXq";
export var justifyContentXxlBetween = "image-text-block-module--justify-content-xxl-between--srBQf";
export var justifyContentXxlCenter = "image-text-block-module--justify-content-xxl-center--07nsT";
export var justifyContentXxlEnd = "image-text-block-module--justify-content-xxl-end--iND8C";
export var justifyContentXxlEvenly = "image-text-block-module--justify-content-xxl-evenly--dohk1";
export var justifyContentXxlStart = "image-text-block-module--justify-content-xxl-start--59AqJ";
export var lh1 = "image-text-block-module--lh-1--dl19g";
export var lhBase = "image-text-block-module--lh-base--k1sn0";
export var lhLg = "image-text-block-module--lh-lg--yUZik";
export var lhSm = "image-text-block-module--lh-sm--LnHJs";
export var linkDanger = "image-text-block-module--link-danger--3lO9D";
export var linkDark = "image-text-block-module--link-dark--Oefgi";
export var linkInfo = "image-text-block-module--link-info--6azK9";
export var linkLight = "image-text-block-module--link-light--2PL2E";
export var linkPrimary = "image-text-block-module--link-primary--sWk0Y";
export var linkSecondary = "image-text-block-module--link-secondary--9oocK";
export var linkSuccess = "image-text-block-module--link-success--YzSFl";
export var linkWarning = "image-text-block-module--link-warning--+-eK4";
export var m0 = "image-text-block-module--m-0--REHLA";
export var m1 = "image-text-block-module--m-1--y1Tlw";
export var m2 = "image-text-block-module--m-2--Mbc8T";
export var m3 = "image-text-block-module--m-3--zRIOJ";
export var m4 = "image-text-block-module--m-4--XPcsO";
export var m5 = "image-text-block-module--m-5--EFWEm";
export var mAuto = "image-text-block-module--m-auto--u6AGR";
export var mLg0 = "image-text-block-module--m-lg-0--HKwR3";
export var mLg1 = "image-text-block-module--m-lg-1--tsDNQ";
export var mLg2 = "image-text-block-module--m-lg-2--8Y70m";
export var mLg3 = "image-text-block-module--m-lg-3--ZJj8m";
export var mLg4 = "image-text-block-module--m-lg-4--1+qyC";
export var mLg5 = "image-text-block-module--m-lg-5--fMnyK";
export var mLgAuto = "image-text-block-module--m-lg-auto--lsacK";
export var mMd0 = "image-text-block-module--m-md-0--Gc5Jx";
export var mMd1 = "image-text-block-module--m-md-1--JY0IH";
export var mMd2 = "image-text-block-module--m-md-2--JglaU";
export var mMd3 = "image-text-block-module--m-md-3--15aEd";
export var mMd4 = "image-text-block-module--m-md-4--Yt9pf";
export var mMd5 = "image-text-block-module--m-md-5--ilNAv";
export var mMdAuto = "image-text-block-module--m-md-auto--zWWh5";
export var mSm0 = "image-text-block-module--m-sm-0--KAb87";
export var mSm1 = "image-text-block-module--m-sm-1--JqomT";
export var mSm2 = "image-text-block-module--m-sm-2--Cxcf1";
export var mSm3 = "image-text-block-module--m-sm-3--dHQ9Z";
export var mSm4 = "image-text-block-module--m-sm-4--yUUUU";
export var mSm5 = "image-text-block-module--m-sm-5--9oDsI";
export var mSmAuto = "image-text-block-module--m-sm-auto--4XJkS";
export var mXl0 = "image-text-block-module--m-xl-0--+sBFZ";
export var mXl1 = "image-text-block-module--m-xl-1--7c6Tp";
export var mXl2 = "image-text-block-module--m-xl-2--PIX-6";
export var mXl3 = "image-text-block-module--m-xl-3--P8zux";
export var mXl4 = "image-text-block-module--m-xl-4--8oObl";
export var mXl5 = "image-text-block-module--m-xl-5--SExjz";
export var mXlAuto = "image-text-block-module--m-xl-auto--HH5oV";
export var mXxl0 = "image-text-block-module--m-xxl-0---QOa3";
export var mXxl1 = "image-text-block-module--m-xxl-1--EU1na";
export var mXxl2 = "image-text-block-module--m-xxl-2---J-cs";
export var mXxl3 = "image-text-block-module--m-xxl-3--nfDjF";
export var mXxl4 = "image-text-block-module--m-xxl-4--Js8Xr";
export var mXxl5 = "image-text-block-module--m-xxl-5--zt-nL";
export var mXxlAuto = "image-text-block-module--m-xxl-auto--19MqW";
export var mb0 = "image-text-block-module--mb-0--ssTNC";
export var mb1 = "image-text-block-module--mb-1--cBMzC";
export var mb2 = "image-text-block-module--mb-2--MdSLt";
export var mb3 = "image-text-block-module--mb-3--do4QY";
export var mb4 = "image-text-block-module--mb-4--FoZlY";
export var mb5 = "image-text-block-module--mb-5--+dkaU";
export var mbAuto = "image-text-block-module--mb-auto--WA+r4";
export var mbLg0 = "image-text-block-module--mb-lg-0--6+7NS";
export var mbLg1 = "image-text-block-module--mb-lg-1--d7Zx-";
export var mbLg2 = "image-text-block-module--mb-lg-2--kkDbO";
export var mbLg3 = "image-text-block-module--mb-lg-3--Axo2z";
export var mbLg4 = "image-text-block-module--mb-lg-4--HwvfC";
export var mbLg5 = "image-text-block-module--mb-lg-5--w+f5H";
export var mbLgAuto = "image-text-block-module--mb-lg-auto--RIW1M";
export var mbMd0 = "image-text-block-module--mb-md-0--Ny6OR";
export var mbMd1 = "image-text-block-module--mb-md-1--Rb4Df";
export var mbMd2 = "image-text-block-module--mb-md-2--HBoLo";
export var mbMd3 = "image-text-block-module--mb-md-3--ule-5";
export var mbMd4 = "image-text-block-module--mb-md-4--kml-c";
export var mbMd5 = "image-text-block-module--mb-md-5--h-Mri";
export var mbMdAuto = "image-text-block-module--mb-md-auto--+Gxk6";
export var mbSm0 = "image-text-block-module--mb-sm-0--ZBimP";
export var mbSm1 = "image-text-block-module--mb-sm-1--qBPqs";
export var mbSm2 = "image-text-block-module--mb-sm-2--6cn4c";
export var mbSm3 = "image-text-block-module--mb-sm-3--l6KiK";
export var mbSm4 = "image-text-block-module--mb-sm-4--AFPV3";
export var mbSm5 = "image-text-block-module--mb-sm-5--BviiT";
export var mbSmAuto = "image-text-block-module--mb-sm-auto--CSwWs";
export var mbXl0 = "image-text-block-module--mb-xl-0--qKZHs";
export var mbXl1 = "image-text-block-module--mb-xl-1--axrZX";
export var mbXl2 = "image-text-block-module--mb-xl-2--SJNpr";
export var mbXl3 = "image-text-block-module--mb-xl-3--APolY";
export var mbXl4 = "image-text-block-module--mb-xl-4--LKhfj";
export var mbXl5 = "image-text-block-module--mb-xl-5--0B1dk";
export var mbXlAuto = "image-text-block-module--mb-xl-auto--Bic7u";
export var mbXxl0 = "image-text-block-module--mb-xxl-0--Z8jJ0";
export var mbXxl1 = "image-text-block-module--mb-xxl-1--8jo3P";
export var mbXxl2 = "image-text-block-module--mb-xxl-2--VYX+9";
export var mbXxl3 = "image-text-block-module--mb-xxl-3--YvGTo";
export var mbXxl4 = "image-text-block-module--mb-xxl-4--eJkD6";
export var mbXxl5 = "image-text-block-module--mb-xxl-5--PsOFf";
export var mbXxlAuto = "image-text-block-module--mb-xxl-auto--Ilyvd";
export var me0 = "image-text-block-module--me-0--4Qc76";
export var me1 = "image-text-block-module--me-1--bLDeo";
export var me2 = "image-text-block-module--me-2--jROIa";
export var me3 = "image-text-block-module--me-3--d2v46";
export var me4 = "image-text-block-module--me-4--bfbXA";
export var me5 = "image-text-block-module--me-5--8uijp";
export var meAuto = "image-text-block-module--me-auto--qMCIF";
export var meLg0 = "image-text-block-module--me-lg-0--BYiGF";
export var meLg1 = "image-text-block-module--me-lg-1--mRxbu";
export var meLg2 = "image-text-block-module--me-lg-2--Kc6zn";
export var meLg3 = "image-text-block-module--me-lg-3--nR4MV";
export var meLg4 = "image-text-block-module--me-lg-4--Sdjuv";
export var meLg5 = "image-text-block-module--me-lg-5--5KiLx";
export var meLgAuto = "image-text-block-module--me-lg-auto--6EcOq";
export var meMd0 = "image-text-block-module--me-md-0--2KruY";
export var meMd1 = "image-text-block-module--me-md-1--RUmH+";
export var meMd2 = "image-text-block-module--me-md-2--MyJCO";
export var meMd3 = "image-text-block-module--me-md-3--d6XUY";
export var meMd4 = "image-text-block-module--me-md-4--Vy1UJ";
export var meMd5 = "image-text-block-module--me-md-5--+pAKX";
export var meMdAuto = "image-text-block-module--me-md-auto--h7Nlh";
export var meSm0 = "image-text-block-module--me-sm-0--PaXd4";
export var meSm1 = "image-text-block-module--me-sm-1--AE+qX";
export var meSm2 = "image-text-block-module--me-sm-2--2CqDo";
export var meSm3 = "image-text-block-module--me-sm-3--3Wcl7";
export var meSm4 = "image-text-block-module--me-sm-4--a6W9a";
export var meSm5 = "image-text-block-module--me-sm-5--wgF7p";
export var meSmAuto = "image-text-block-module--me-sm-auto--9nMtR";
export var meXl0 = "image-text-block-module--me-xl-0--et6Xz";
export var meXl1 = "image-text-block-module--me-xl-1--W4pLo";
export var meXl2 = "image-text-block-module--me-xl-2--4PoVt";
export var meXl3 = "image-text-block-module--me-xl-3--X7zEc";
export var meXl4 = "image-text-block-module--me-xl-4--xeU6t";
export var meXl5 = "image-text-block-module--me-xl-5--E9VJs";
export var meXlAuto = "image-text-block-module--me-xl-auto--9gGab";
export var meXxl0 = "image-text-block-module--me-xxl-0--q-4Qw";
export var meXxl1 = "image-text-block-module--me-xxl-1--bXxKh";
export var meXxl2 = "image-text-block-module--me-xxl-2--CWi3l";
export var meXxl3 = "image-text-block-module--me-xxl-3--0U7yy";
export var meXxl4 = "image-text-block-module--me-xxl-4--xZCVf";
export var meXxl5 = "image-text-block-module--me-xxl-5--zanPp";
export var meXxlAuto = "image-text-block-module--me-xxl-auto--WDPTW";
export var mh100 = "image-text-block-module--mh-100--vnGL6";
export var minVh100 = "image-text-block-module--min-vh-100--LJfcw";
export var minVw100 = "image-text-block-module--min-vw-100--Rc+7Q";
export var ms0 = "image-text-block-module--ms-0--zeauI";
export var ms1 = "image-text-block-module--ms-1--+UllZ";
export var ms2 = "image-text-block-module--ms-2--V3uLG";
export var ms3 = "image-text-block-module--ms-3--V3gl-";
export var ms4 = "image-text-block-module--ms-4--dECw8";
export var ms5 = "image-text-block-module--ms-5--cGl-R";
export var msAuto = "image-text-block-module--ms-auto--VTB0n";
export var msLg0 = "image-text-block-module--ms-lg-0--2VbG7";
export var msLg1 = "image-text-block-module--ms-lg-1--ej4OV";
export var msLg2 = "image-text-block-module--ms-lg-2--yID-v";
export var msLg3 = "image-text-block-module--ms-lg-3--1iWd5";
export var msLg4 = "image-text-block-module--ms-lg-4--ExYet";
export var msLg5 = "image-text-block-module--ms-lg-5--YKaex";
export var msLgAuto = "image-text-block-module--ms-lg-auto--9hBzw";
export var msMd0 = "image-text-block-module--ms-md-0--JJctd";
export var msMd1 = "image-text-block-module--ms-md-1--18RrA";
export var msMd2 = "image-text-block-module--ms-md-2--5+1-A";
export var msMd3 = "image-text-block-module--ms-md-3--hr+Wa";
export var msMd4 = "image-text-block-module--ms-md-4--MpD9U";
export var msMd5 = "image-text-block-module--ms-md-5--OXlBh";
export var msMdAuto = "image-text-block-module--ms-md-auto--81iwy";
export var msSm0 = "image-text-block-module--ms-sm-0--IhkOG";
export var msSm1 = "image-text-block-module--ms-sm-1--7nN75";
export var msSm2 = "image-text-block-module--ms-sm-2--B7IJ1";
export var msSm3 = "image-text-block-module--ms-sm-3--Dja-Y";
export var msSm4 = "image-text-block-module--ms-sm-4--2NYP0";
export var msSm5 = "image-text-block-module--ms-sm-5--yXStx";
export var msSmAuto = "image-text-block-module--ms-sm-auto--aQKIZ";
export var msXl0 = "image-text-block-module--ms-xl-0--HOTkv";
export var msXl1 = "image-text-block-module--ms-xl-1--WVE09";
export var msXl2 = "image-text-block-module--ms-xl-2--9fjRh";
export var msXl3 = "image-text-block-module--ms-xl-3--aO639";
export var msXl4 = "image-text-block-module--ms-xl-4--UpppT";
export var msXl5 = "image-text-block-module--ms-xl-5--ZbaQ+";
export var msXlAuto = "image-text-block-module--ms-xl-auto--xVCrz";
export var msXxl0 = "image-text-block-module--ms-xxl-0--xO-Mb";
export var msXxl1 = "image-text-block-module--ms-xxl-1--3icdc";
export var msXxl2 = "image-text-block-module--ms-xxl-2--V7sbh";
export var msXxl3 = "image-text-block-module--ms-xxl-3---+UJb";
export var msXxl4 = "image-text-block-module--ms-xxl-4--9kVgM";
export var msXxl5 = "image-text-block-module--ms-xxl-5--YcmTo";
export var msXxlAuto = "image-text-block-module--ms-xxl-auto--+zGow";
export var mt0 = "image-text-block-module--mt-0--quBPX";
export var mt1 = "image-text-block-module--mt-1--VMTua";
export var mt2 = "image-text-block-module--mt-2--rs+BO";
export var mt3 = "image-text-block-module--mt-3--rMCTs";
export var mt4 = "image-text-block-module--mt-4--YKOCD";
export var mt5 = "image-text-block-module--mt-5--qnq6P";
export var mtAuto = "image-text-block-module--mt-auto--UdJQ9";
export var mtLg0 = "image-text-block-module--mt-lg-0--FYtwv";
export var mtLg1 = "image-text-block-module--mt-lg-1--TqpU5";
export var mtLg2 = "image-text-block-module--mt-lg-2--r79Oh";
export var mtLg3 = "image-text-block-module--mt-lg-3--tYtwe";
export var mtLg4 = "image-text-block-module--mt-lg-4--ZuRqo";
export var mtLg5 = "image-text-block-module--mt-lg-5--soiJq";
export var mtLgAuto = "image-text-block-module--mt-lg-auto--kcx6o";
export var mtMd0 = "image-text-block-module--mt-md-0--ta-n-";
export var mtMd1 = "image-text-block-module--mt-md-1--LDrTC";
export var mtMd2 = "image-text-block-module--mt-md-2--a4wLo";
export var mtMd3 = "image-text-block-module--mt-md-3--4QWSX";
export var mtMd4 = "image-text-block-module--mt-md-4--JOmZN";
export var mtMd5 = "image-text-block-module--mt-md-5---8kxP";
export var mtMdAuto = "image-text-block-module--mt-md-auto--iAMEy";
export var mtSm0 = "image-text-block-module--mt-sm-0--Wrw7C";
export var mtSm1 = "image-text-block-module--mt-sm-1--RgfGh";
export var mtSm2 = "image-text-block-module--mt-sm-2--dbN3v";
export var mtSm3 = "image-text-block-module--mt-sm-3--39llv";
export var mtSm4 = "image-text-block-module--mt-sm-4--uNskb";
export var mtSm5 = "image-text-block-module--mt-sm-5--ZHxMq";
export var mtSmAuto = "image-text-block-module--mt-sm-auto--oIRCA";
export var mtXl0 = "image-text-block-module--mt-xl-0--femSh";
export var mtXl1 = "image-text-block-module--mt-xl-1--RXryn";
export var mtXl2 = "image-text-block-module--mt-xl-2--a7VRf";
export var mtXl3 = "image-text-block-module--mt-xl-3--k1aye";
export var mtXl4 = "image-text-block-module--mt-xl-4--mCsng";
export var mtXl5 = "image-text-block-module--mt-xl-5--oZuqp";
export var mtXlAuto = "image-text-block-module--mt-xl-auto--bcdxB";
export var mtXxl0 = "image-text-block-module--mt-xxl-0--p3sri";
export var mtXxl1 = "image-text-block-module--mt-xxl-1--kDufG";
export var mtXxl2 = "image-text-block-module--mt-xxl-2--iEaY5";
export var mtXxl3 = "image-text-block-module--mt-xxl-3--MQCcT";
export var mtXxl4 = "image-text-block-module--mt-xxl-4--htkVZ";
export var mtXxl5 = "image-text-block-module--mt-xxl-5--uNkwO";
export var mtXxlAuto = "image-text-block-module--mt-xxl-auto--RnF0d";
export var mw100 = "image-text-block-module--mw-100--tZa9o";
export var mx0 = "image-text-block-module--mx-0--A0e7t";
export var mx1 = "image-text-block-module--mx-1--cnco3";
export var mx2 = "image-text-block-module--mx-2--516S+";
export var mx3 = "image-text-block-module--mx-3--fg1RJ";
export var mx4 = "image-text-block-module--mx-4--2xp3o";
export var mx5 = "image-text-block-module--mx-5--evmxK";
export var mxAuto = "image-text-block-module--mx-auto--L9pLI";
export var mxLg0 = "image-text-block-module--mx-lg-0--TyvuC";
export var mxLg1 = "image-text-block-module--mx-lg-1--VKrru";
export var mxLg2 = "image-text-block-module--mx-lg-2--3WGLZ";
export var mxLg3 = "image-text-block-module--mx-lg-3--RDcGK";
export var mxLg4 = "image-text-block-module--mx-lg-4--Ffo7v";
export var mxLg5 = "image-text-block-module--mx-lg-5--5Hbga";
export var mxLgAuto = "image-text-block-module--mx-lg-auto--EtQ+r";
export var mxMd0 = "image-text-block-module--mx-md-0--pHsoV";
export var mxMd1 = "image-text-block-module--mx-md-1--k6dYi";
export var mxMd2 = "image-text-block-module--mx-md-2--ftxKM";
export var mxMd3 = "image-text-block-module--mx-md-3--SPZJc";
export var mxMd4 = "image-text-block-module--mx-md-4--tN0cx";
export var mxMd5 = "image-text-block-module--mx-md-5--lu5BR";
export var mxMdAuto = "image-text-block-module--mx-md-auto--kAuFn";
export var mxSm0 = "image-text-block-module--mx-sm-0--q9KnC";
export var mxSm1 = "image-text-block-module--mx-sm-1--+rTTf";
export var mxSm2 = "image-text-block-module--mx-sm-2--F2yWP";
export var mxSm3 = "image-text-block-module--mx-sm-3--psTrC";
export var mxSm4 = "image-text-block-module--mx-sm-4--WHsn6";
export var mxSm5 = "image-text-block-module--mx-sm-5--orxzg";
export var mxSmAuto = "image-text-block-module--mx-sm-auto--amaKH";
export var mxXl0 = "image-text-block-module--mx-xl-0--juxxB";
export var mxXl1 = "image-text-block-module--mx-xl-1--4nvKw";
export var mxXl2 = "image-text-block-module--mx-xl-2--dx8kB";
export var mxXl3 = "image-text-block-module--mx-xl-3--VsSpw";
export var mxXl4 = "image-text-block-module--mx-xl-4--zlXms";
export var mxXl5 = "image-text-block-module--mx-xl-5--vnsRI";
export var mxXlAuto = "image-text-block-module--mx-xl-auto--TXGSh";
export var mxXxl0 = "image-text-block-module--mx-xxl-0--QGtCD";
export var mxXxl1 = "image-text-block-module--mx-xxl-1--F-DEB";
export var mxXxl2 = "image-text-block-module--mx-xxl-2--nDkN5";
export var mxXxl3 = "image-text-block-module--mx-xxl-3--76KOJ";
export var mxXxl4 = "image-text-block-module--mx-xxl-4--VY1GD";
export var mxXxl5 = "image-text-block-module--mx-xxl-5--EMFlz";
export var mxXxlAuto = "image-text-block-module--mx-xxl-auto--3EhaZ";
export var my0 = "image-text-block-module--my-0--VSt9u";
export var my1 = "image-text-block-module--my-1--4gL9-";
export var my2 = "image-text-block-module--my-2--yz7RL";
export var my3 = "image-text-block-module--my-3--T4O+k";
export var my4 = "image-text-block-module--my-4--0CcBq";
export var my5 = "image-text-block-module--my-5--omYwX";
export var myAuto = "image-text-block-module--my-auto--kYFYp";
export var myLg0 = "image-text-block-module--my-lg-0--nZHkr";
export var myLg1 = "image-text-block-module--my-lg-1--bXZyS";
export var myLg2 = "image-text-block-module--my-lg-2--a4AyE";
export var myLg3 = "image-text-block-module--my-lg-3--+1CjJ";
export var myLg4 = "image-text-block-module--my-lg-4--12OKs";
export var myLg5 = "image-text-block-module--my-lg-5--RTDS8";
export var myLgAuto = "image-text-block-module--my-lg-auto--a-YKX";
export var myMd0 = "image-text-block-module--my-md-0--g9wEu";
export var myMd1 = "image-text-block-module--my-md-1--bMca9";
export var myMd2 = "image-text-block-module--my-md-2--4yz3l";
export var myMd3 = "image-text-block-module--my-md-3--x5kmL";
export var myMd4 = "image-text-block-module--my-md-4--bLV7+";
export var myMd5 = "image-text-block-module--my-md-5--wP5Wu";
export var myMdAuto = "image-text-block-module--my-md-auto--+4uVp";
export var mySm0 = "image-text-block-module--my-sm-0--MRTWn";
export var mySm1 = "image-text-block-module--my-sm-1--pnM4+";
export var mySm2 = "image-text-block-module--my-sm-2--9uyPe";
export var mySm3 = "image-text-block-module--my-sm-3--i9-md";
export var mySm4 = "image-text-block-module--my-sm-4--JA-+q";
export var mySm5 = "image-text-block-module--my-sm-5--s8z4h";
export var mySmAuto = "image-text-block-module--my-sm-auto--elimi";
export var myXl0 = "image-text-block-module--my-xl-0--tdpRq";
export var myXl1 = "image-text-block-module--my-xl-1--JUlfu";
export var myXl2 = "image-text-block-module--my-xl-2--KfA1z";
export var myXl3 = "image-text-block-module--my-xl-3--F5JyX";
export var myXl4 = "image-text-block-module--my-xl-4--IEcLE";
export var myXl5 = "image-text-block-module--my-xl-5--RUNYq";
export var myXlAuto = "image-text-block-module--my-xl-auto--t1wkZ";
export var myXxl0 = "image-text-block-module--my-xxl-0--Xm0sy";
export var myXxl1 = "image-text-block-module--my-xxl-1--O8Sn2";
export var myXxl2 = "image-text-block-module--my-xxl-2--HjpZy";
export var myXxl3 = "image-text-block-module--my-xxl-3--fXKXz";
export var myXxl4 = "image-text-block-module--my-xxl-4--wWaxV";
export var myXxl5 = "image-text-block-module--my-xxl-5--7DSH8";
export var myXxlAuto = "image-text-block-module--my-xxl-auto--P6h8f";
export var opacity0 = "image-text-block-module--opacity-0--xBFCK";
export var opacity100 = "image-text-block-module--opacity-100--iHg8K";
export var opacity25 = "image-text-block-module--opacity-25--8cWq9";
export var opacity50 = "image-text-block-module--opacity-50--MQO2K";
export var opacity75 = "image-text-block-module--opacity-75--v9s1p";
export var order0 = "image-text-block-module--order-0--tTLzf";
export var order1 = "image-text-block-module--order-1--QvVsv";
export var order2 = "image-text-block-module--order-2--50O3z";
export var order3 = "image-text-block-module--order-3---5F0Y";
export var order4 = "image-text-block-module--order-4--LU7X2";
export var order5 = "image-text-block-module--order-5--Bp4Ni";
export var orderFirst = "image-text-block-module--order-first--Bra6m";
export var orderLast = "image-text-block-module--order-last--EngUO";
export var orderLg0 = "image-text-block-module--order-lg-0--QkujA";
export var orderLg1 = "image-text-block-module--order-lg-1--bvPsF";
export var orderLg2 = "image-text-block-module--order-lg-2--aADMh";
export var orderLg3 = "image-text-block-module--order-lg-3--xciUP";
export var orderLg4 = "image-text-block-module--order-lg-4--xw+hf";
export var orderLg5 = "image-text-block-module--order-lg-5--pijrf";
export var orderLgFirst = "image-text-block-module--order-lg-first--nwFrb";
export var orderLgLast = "image-text-block-module--order-lg-last--x3aFA";
export var orderMd0 = "image-text-block-module--order-md-0--hH352";
export var orderMd1 = "image-text-block-module--order-md-1--xX5We";
export var orderMd2 = "image-text-block-module--order-md-2--ZEPuO";
export var orderMd3 = "image-text-block-module--order-md-3--4NPmz";
export var orderMd4 = "image-text-block-module--order-md-4--Bqzr+";
export var orderMd5 = "image-text-block-module--order-md-5--ZgNwn";
export var orderMdFirst = "image-text-block-module--order-md-first--Q1IZm";
export var orderMdLast = "image-text-block-module--order-md-last--q2QZj";
export var orderSm0 = "image-text-block-module--order-sm-0--qDx3x";
export var orderSm1 = "image-text-block-module--order-sm-1--l756b";
export var orderSm2 = "image-text-block-module--order-sm-2--4SZag";
export var orderSm3 = "image-text-block-module--order-sm-3--MWGEk";
export var orderSm4 = "image-text-block-module--order-sm-4--eeJvO";
export var orderSm5 = "image-text-block-module--order-sm-5--CWMXi";
export var orderSmFirst = "image-text-block-module--order-sm-first--xCfw3";
export var orderSmLast = "image-text-block-module--order-sm-last--qOckw";
export var orderXl0 = "image-text-block-module--order-xl-0--J1ukP";
export var orderXl1 = "image-text-block-module--order-xl-1--D6pci";
export var orderXl2 = "image-text-block-module--order-xl-2--4DKUi";
export var orderXl3 = "image-text-block-module--order-xl-3--elNsT";
export var orderXl4 = "image-text-block-module--order-xl-4--db5en";
export var orderXl5 = "image-text-block-module--order-xl-5--Fbzdg";
export var orderXlFirst = "image-text-block-module--order-xl-first--bIjqw";
export var orderXlLast = "image-text-block-module--order-xl-last--gGv8G";
export var orderXxl0 = "image-text-block-module--order-xxl-0--VwTOj";
export var orderXxl1 = "image-text-block-module--order-xxl-1--OP1h-";
export var orderXxl2 = "image-text-block-module--order-xxl-2--HgsiR";
export var orderXxl3 = "image-text-block-module--order-xxl-3--oAwDa";
export var orderXxl4 = "image-text-block-module--order-xxl-4--mYzqJ";
export var orderXxl5 = "image-text-block-module--order-xxl-5--ECv2c";
export var orderXxlFirst = "image-text-block-module--order-xxl-first--h1-ZC";
export var orderXxlLast = "image-text-block-module--order-xxl-last--yLnJ3";
export var overflowAuto = "image-text-block-module--overflow-auto--rNFaE";
export var overflowHidden = "image-text-block-module--overflow-hidden--QAuB6";
export var overflowScroll = "image-text-block-module--overflow-scroll--plQ6o";
export var overflowVisible = "image-text-block-module--overflow-visible--CjMgR";
export var p0 = "image-text-block-module--p-0--YMBMA";
export var p1 = "image-text-block-module--p-1--JvYOH";
export var p2 = "image-text-block-module--p-2--lBbwX";
export var p3 = "image-text-block-module--p-3--SZcbW";
export var p4 = "image-text-block-module--p-4--vvLov";
export var p5 = "image-text-block-module--p-5--ZV9ab";
export var pLg0 = "image-text-block-module--p-lg-0--embC5";
export var pLg1 = "image-text-block-module--p-lg-1--k3DdK";
export var pLg2 = "image-text-block-module--p-lg-2--QqyJ4";
export var pLg3 = "image-text-block-module--p-lg-3--aDYUx";
export var pLg4 = "image-text-block-module--p-lg-4--vokV7";
export var pLg5 = "image-text-block-module--p-lg-5--oWYLX";
export var pMd0 = "image-text-block-module--p-md-0--BRXJt";
export var pMd1 = "image-text-block-module--p-md-1--XIBPv";
export var pMd2 = "image-text-block-module--p-md-2--xDEHg";
export var pMd3 = "image-text-block-module--p-md-3--cbHQn";
export var pMd4 = "image-text-block-module--p-md-4--uJPdK";
export var pMd5 = "image-text-block-module--p-md-5--9KMt9";
export var pSm0 = "image-text-block-module--p-sm-0--eOyVk";
export var pSm1 = "image-text-block-module--p-sm-1--oQW+e";
export var pSm2 = "image-text-block-module--p-sm-2--qDUjb";
export var pSm3 = "image-text-block-module--p-sm-3--YFTl9";
export var pSm4 = "image-text-block-module--p-sm-4--m11OG";
export var pSm5 = "image-text-block-module--p-sm-5--IXoqj";
export var pXl0 = "image-text-block-module--p-xl-0--TtVZu";
export var pXl1 = "image-text-block-module--p-xl-1--lkIN8";
export var pXl2 = "image-text-block-module--p-xl-2--Hp1P1";
export var pXl3 = "image-text-block-module--p-xl-3--Tg0nH";
export var pXl4 = "image-text-block-module--p-xl-4--Gk5xC";
export var pXl5 = "image-text-block-module--p-xl-5--Vji8j";
export var pXxl0 = "image-text-block-module--p-xxl-0--lrNwq";
export var pXxl1 = "image-text-block-module--p-xxl-1--SrgDi";
export var pXxl2 = "image-text-block-module--p-xxl-2--4Hzzr";
export var pXxl3 = "image-text-block-module--p-xxl-3--IWmT8";
export var pXxl4 = "image-text-block-module--p-xxl-4--QMDNY";
export var pXxl5 = "image-text-block-module--p-xxl-5--vXqBy";
export var pb0 = "image-text-block-module--pb-0--2F+Kq";
export var pb1 = "image-text-block-module--pb-1--+UOyi";
export var pb2 = "image-text-block-module--pb-2--enI7O";
export var pb3 = "image-text-block-module--pb-3--BWm6j";
export var pb4 = "image-text-block-module--pb-4--JEogQ";
export var pb5 = "image-text-block-module--pb-5--FVngz";
export var pbLg0 = "image-text-block-module--pb-lg-0--x-kZG";
export var pbLg1 = "image-text-block-module--pb-lg-1--obMCW";
export var pbLg2 = "image-text-block-module--pb-lg-2--3KRno";
export var pbLg3 = "image-text-block-module--pb-lg-3--Ozv7F";
export var pbLg4 = "image-text-block-module--pb-lg-4--hjRYJ";
export var pbLg5 = "image-text-block-module--pb-lg-5--oaUty";
export var pbMd0 = "image-text-block-module--pb-md-0--Wp3E1";
export var pbMd1 = "image-text-block-module--pb-md-1--dIhsk";
export var pbMd2 = "image-text-block-module--pb-md-2--MFKHT";
export var pbMd3 = "image-text-block-module--pb-md-3--uRcm5";
export var pbMd4 = "image-text-block-module--pb-md-4--Vn5rd";
export var pbMd5 = "image-text-block-module--pb-md-5--SkPBa";
export var pbSm0 = "image-text-block-module--pb-sm-0--h62c8";
export var pbSm1 = "image-text-block-module--pb-sm-1--Lfkcp";
export var pbSm2 = "image-text-block-module--pb-sm-2--WNgcN";
export var pbSm3 = "image-text-block-module--pb-sm-3--MADDj";
export var pbSm4 = "image-text-block-module--pb-sm-4--16nBR";
export var pbSm5 = "image-text-block-module--pb-sm-5--uaRTP";
export var pbXl0 = "image-text-block-module--pb-xl-0--f1BQb";
export var pbXl1 = "image-text-block-module--pb-xl-1--Pt4aM";
export var pbXl2 = "image-text-block-module--pb-xl-2--BMNpV";
export var pbXl3 = "image-text-block-module--pb-xl-3--GIkF9";
export var pbXl4 = "image-text-block-module--pb-xl-4--pafFN";
export var pbXl5 = "image-text-block-module--pb-xl-5--uxgFv";
export var pbXxl0 = "image-text-block-module--pb-xxl-0--toQ9m";
export var pbXxl1 = "image-text-block-module--pb-xxl-1--CxKau";
export var pbXxl2 = "image-text-block-module--pb-xxl-2--36EnB";
export var pbXxl3 = "image-text-block-module--pb-xxl-3--AKZ-a";
export var pbXxl4 = "image-text-block-module--pb-xxl-4--cdXj8";
export var pbXxl5 = "image-text-block-module--pb-xxl-5--G+wi9";
export var pe0 = "image-text-block-module--pe-0--CXMBn";
export var pe1 = "image-text-block-module--pe-1--Iid2d";
export var pe2 = "image-text-block-module--pe-2--b5oXT";
export var pe3 = "image-text-block-module--pe-3--gimOR";
export var pe4 = "image-text-block-module--pe-4--ol8Q4";
export var pe5 = "image-text-block-module--pe-5--sPrJ9";
export var peAuto = "image-text-block-module--pe-auto--NAhZO";
export var peLg0 = "image-text-block-module--pe-lg-0--vYvBk";
export var peLg1 = "image-text-block-module--pe-lg-1--Xtga7";
export var peLg2 = "image-text-block-module--pe-lg-2--fzn6I";
export var peLg3 = "image-text-block-module--pe-lg-3--izzhq";
export var peLg4 = "image-text-block-module--pe-lg-4--tDHdB";
export var peLg5 = "image-text-block-module--pe-lg-5--i6qpX";
export var peMd0 = "image-text-block-module--pe-md-0--+ZAct";
export var peMd1 = "image-text-block-module--pe-md-1--TW3eH";
export var peMd2 = "image-text-block-module--pe-md-2--eNKnz";
export var peMd3 = "image-text-block-module--pe-md-3--Hqp4H";
export var peMd4 = "image-text-block-module--pe-md-4--jkhXW";
export var peMd5 = "image-text-block-module--pe-md-5--fXe14";
export var peNone = "image-text-block-module--pe-none--ijqo4";
export var peSm0 = "image-text-block-module--pe-sm-0--9wH+P";
export var peSm1 = "image-text-block-module--pe-sm-1--+F0JX";
export var peSm2 = "image-text-block-module--pe-sm-2--m7woO";
export var peSm3 = "image-text-block-module--pe-sm-3--Bz24S";
export var peSm4 = "image-text-block-module--pe-sm-4--+m+OX";
export var peSm5 = "image-text-block-module--pe-sm-5--tzh5B";
export var peXl0 = "image-text-block-module--pe-xl-0---vOaC";
export var peXl1 = "image-text-block-module--pe-xl-1--4LcBN";
export var peXl2 = "image-text-block-module--pe-xl-2--HrxSY";
export var peXl3 = "image-text-block-module--pe-xl-3--9mleU";
export var peXl4 = "image-text-block-module--pe-xl-4--bySl5";
export var peXl5 = "image-text-block-module--pe-xl-5--yDE8i";
export var peXxl0 = "image-text-block-module--pe-xxl-0--Cu49J";
export var peXxl1 = "image-text-block-module--pe-xxl-1--LkvmZ";
export var peXxl2 = "image-text-block-module--pe-xxl-2---2tqW";
export var peXxl3 = "image-text-block-module--pe-xxl-3--EjOG6";
export var peXxl4 = "image-text-block-module--pe-xxl-4--r+-78";
export var peXxl5 = "image-text-block-module--pe-xxl-5--nbzqz";
export var positionAbsolute = "image-text-block-module--position-absolute--CyJLm";
export var positionFixed = "image-text-block-module--position-fixed--R2c4J";
export var positionRelative = "image-text-block-module--position-relative--xuKt8";
export var positionStatic = "image-text-block-module--position-static--dzviT";
export var positionSticky = "image-text-block-module--position-sticky--hnDDm";
export var ps0 = "image-text-block-module--ps-0--5RZuJ";
export var ps1 = "image-text-block-module--ps-1--e5p-i";
export var ps2 = "image-text-block-module--ps-2--w6W-8";
export var ps3 = "image-text-block-module--ps-3--hfkEL";
export var ps4 = "image-text-block-module--ps-4--IKu43";
export var ps5 = "image-text-block-module--ps-5--hyBtu";
export var psLg0 = "image-text-block-module--ps-lg-0--lrv46";
export var psLg1 = "image-text-block-module--ps-lg-1--BigZ6";
export var psLg2 = "image-text-block-module--ps-lg-2--T2mZ+";
export var psLg3 = "image-text-block-module--ps-lg-3--yo7zP";
export var psLg4 = "image-text-block-module--ps-lg-4--8JXzD";
export var psLg5 = "image-text-block-module--ps-lg-5--tPSX5";
export var psMd0 = "image-text-block-module--ps-md-0--+b6xd";
export var psMd1 = "image-text-block-module--ps-md-1--MxVQy";
export var psMd2 = "image-text-block-module--ps-md-2--k4JYb";
export var psMd3 = "image-text-block-module--ps-md-3--zpUlc";
export var psMd4 = "image-text-block-module--ps-md-4--qaSDs";
export var psMd5 = "image-text-block-module--ps-md-5--nP6hi";
export var psSm0 = "image-text-block-module--ps-sm-0--FM5Xe";
export var psSm1 = "image-text-block-module--ps-sm-1--HQ3LW";
export var psSm2 = "image-text-block-module--ps-sm-2--fEtrC";
export var psSm3 = "image-text-block-module--ps-sm-3--fMK0G";
export var psSm4 = "image-text-block-module--ps-sm-4--0JFYI";
export var psSm5 = "image-text-block-module--ps-sm-5--pTULm";
export var psXl0 = "image-text-block-module--ps-xl-0--OSPyD";
export var psXl1 = "image-text-block-module--ps-xl-1--+Zn79";
export var psXl2 = "image-text-block-module--ps-xl-2--WWpq7";
export var psXl3 = "image-text-block-module--ps-xl-3--R2RrL";
export var psXl4 = "image-text-block-module--ps-xl-4--tt1gQ";
export var psXl5 = "image-text-block-module--ps-xl-5--xXOPa";
export var psXxl0 = "image-text-block-module--ps-xxl-0--n2OHx";
export var psXxl1 = "image-text-block-module--ps-xxl-1--xAeIp";
export var psXxl2 = "image-text-block-module--ps-xxl-2--JF15y";
export var psXxl3 = "image-text-block-module--ps-xxl-3--wOkOa";
export var psXxl4 = "image-text-block-module--ps-xxl-4--UxxH3";
export var psXxl5 = "image-text-block-module--ps-xxl-5--unhCt";
export var pt0 = "image-text-block-module--pt-0--+H4vZ";
export var pt1 = "image-text-block-module--pt-1--FA-Ed";
export var pt2 = "image-text-block-module--pt-2--UP9vG";
export var pt3 = "image-text-block-module--pt-3--1tqxV";
export var pt4 = "image-text-block-module--pt-4--8AZV+";
export var pt5 = "image-text-block-module--pt-5--DjKEV";
export var ptLg0 = "image-text-block-module--pt-lg-0--H9Ov3";
export var ptLg1 = "image-text-block-module--pt-lg-1--BaqAb";
export var ptLg2 = "image-text-block-module--pt-lg-2--1u7W2";
export var ptLg3 = "image-text-block-module--pt-lg-3--LXFkP";
export var ptLg4 = "image-text-block-module--pt-lg-4--y81XZ";
export var ptLg5 = "image-text-block-module--pt-lg-5--+wlT5";
export var ptMd0 = "image-text-block-module--pt-md-0--mLZrE";
export var ptMd1 = "image-text-block-module--pt-md-1--oZvqb";
export var ptMd2 = "image-text-block-module--pt-md-2--0Kn5L";
export var ptMd3 = "image-text-block-module--pt-md-3--LfBMh";
export var ptMd4 = "image-text-block-module--pt-md-4--jC-Kj";
export var ptMd5 = "image-text-block-module--pt-md-5--qLeMP";
export var ptSm0 = "image-text-block-module--pt-sm-0--cHLUJ";
export var ptSm1 = "image-text-block-module--pt-sm-1--wIGnF";
export var ptSm2 = "image-text-block-module--pt-sm-2--eNfBF";
export var ptSm3 = "image-text-block-module--pt-sm-3--74cdT";
export var ptSm4 = "image-text-block-module--pt-sm-4--8U3MH";
export var ptSm5 = "image-text-block-module--pt-sm-5--D08f3";
export var ptXl0 = "image-text-block-module--pt-xl-0--2U11R";
export var ptXl1 = "image-text-block-module--pt-xl-1--Y7UHk";
export var ptXl2 = "image-text-block-module--pt-xl-2--D0S5x";
export var ptXl3 = "image-text-block-module--pt-xl-3--jTmu1";
export var ptXl4 = "image-text-block-module--pt-xl-4--OFU+w";
export var ptXl5 = "image-text-block-module--pt-xl-5--htJrb";
export var ptXxl0 = "image-text-block-module--pt-xxl-0--FAN5e";
export var ptXxl1 = "image-text-block-module--pt-xxl-1--7X5IL";
export var ptXxl2 = "image-text-block-module--pt-xxl-2--YgI7Q";
export var ptXxl3 = "image-text-block-module--pt-xxl-3--eEBdu";
export var ptXxl4 = "image-text-block-module--pt-xxl-4--NaGoo";
export var ptXxl5 = "image-text-block-module--pt-xxl-5--AYO3y";
export var px0 = "image-text-block-module--px-0--kBVmy";
export var px1 = "image-text-block-module--px-1--bDCo2";
export var px2 = "image-text-block-module--px-2--h8t2E";
export var px3 = "image-text-block-module--px-3--q1Izb";
export var px4 = "image-text-block-module--px-4--Tp0GE";
export var px5 = "image-text-block-module--px-5--exFW6";
export var pxLg0 = "image-text-block-module--px-lg-0--hy-Bi";
export var pxLg1 = "image-text-block-module--px-lg-1--fbqtg";
export var pxLg2 = "image-text-block-module--px-lg-2--pgOvs";
export var pxLg3 = "image-text-block-module--px-lg-3--QAKvc";
export var pxLg4 = "image-text-block-module--px-lg-4--CgynD";
export var pxLg5 = "image-text-block-module--px-lg-5--fPiW1";
export var pxMd0 = "image-text-block-module--px-md-0--eremm";
export var pxMd1 = "image-text-block-module--px-md-1--x7Jlq";
export var pxMd2 = "image-text-block-module--px-md-2--SLpza";
export var pxMd3 = "image-text-block-module--px-md-3--9vJBh";
export var pxMd4 = "image-text-block-module--px-md-4--7ObGk";
export var pxMd5 = "image-text-block-module--px-md-5--C5G49";
export var pxSm0 = "image-text-block-module--px-sm-0---kxO0";
export var pxSm1 = "image-text-block-module--px-sm-1--yqRgm";
export var pxSm2 = "image-text-block-module--px-sm-2--UogjV";
export var pxSm3 = "image-text-block-module--px-sm-3--+R7da";
export var pxSm4 = "image-text-block-module--px-sm-4--82FTa";
export var pxSm5 = "image-text-block-module--px-sm-5--Y8hwV";
export var pxXl0 = "image-text-block-module--px-xl-0--wZl8M";
export var pxXl1 = "image-text-block-module--px-xl-1--J2fqf";
export var pxXl2 = "image-text-block-module--px-xl-2--9O66-";
export var pxXl3 = "image-text-block-module--px-xl-3--IVnWe";
export var pxXl4 = "image-text-block-module--px-xl-4--yFsRB";
export var pxXl5 = "image-text-block-module--px-xl-5--YnNoX";
export var pxXxl0 = "image-text-block-module--px-xxl-0--Wd+b3";
export var pxXxl1 = "image-text-block-module--px-xxl-1--EzCvf";
export var pxXxl2 = "image-text-block-module--px-xxl-2--6ocmD";
export var pxXxl3 = "image-text-block-module--px-xxl-3--EMtoc";
export var pxXxl4 = "image-text-block-module--px-xxl-4--6GBQ5";
export var pxXxl5 = "image-text-block-module--px-xxl-5--2560g";
export var py0 = "image-text-block-module--py-0--h24HW";
export var py1 = "image-text-block-module--py-1--wpnLZ";
export var py2 = "image-text-block-module--py-2--hIhYu";
export var py3 = "image-text-block-module--py-3--LgdXu";
export var py4 = "image-text-block-module--py-4--Am1Zd";
export var py5 = "image-text-block-module--py-5--T5EEZ";
export var pyLg0 = "image-text-block-module--py-lg-0--yXj1p";
export var pyLg1 = "image-text-block-module--py-lg-1--LSbWA";
export var pyLg2 = "image-text-block-module--py-lg-2--X2GPS";
export var pyLg3 = "image-text-block-module--py-lg-3--7yGmz";
export var pyLg4 = "image-text-block-module--py-lg-4--YdmDt";
export var pyLg5 = "image-text-block-module--py-lg-5--zDpfs";
export var pyMd0 = "image-text-block-module--py-md-0--jBp+2";
export var pyMd1 = "image-text-block-module--py-md-1--EPnSx";
export var pyMd2 = "image-text-block-module--py-md-2--5Ifgz";
export var pyMd3 = "image-text-block-module--py-md-3--BF9kq";
export var pyMd4 = "image-text-block-module--py-md-4--T3jmI";
export var pyMd5 = "image-text-block-module--py-md-5--BjswH";
export var pySm0 = "image-text-block-module--py-sm-0--DbONl";
export var pySm1 = "image-text-block-module--py-sm-1--22TjC";
export var pySm2 = "image-text-block-module--py-sm-2--vjUC6";
export var pySm3 = "image-text-block-module--py-sm-3--y2m0G";
export var pySm4 = "image-text-block-module--py-sm-4--krCso";
export var pySm5 = "image-text-block-module--py-sm-5--XR-sS";
export var pyXl0 = "image-text-block-module--py-xl-0--rJhiD";
export var pyXl1 = "image-text-block-module--py-xl-1--1l+-E";
export var pyXl2 = "image-text-block-module--py-xl-2--ZyGN4";
export var pyXl3 = "image-text-block-module--py-xl-3--eG+tJ";
export var pyXl4 = "image-text-block-module--py-xl-4--8tU1y";
export var pyXl5 = "image-text-block-module--py-xl-5--1eLnN";
export var pyXxl0 = "image-text-block-module--py-xxl-0--ZmrmJ";
export var pyXxl1 = "image-text-block-module--py-xxl-1--qhmGs";
export var pyXxl2 = "image-text-block-module--py-xxl-2--aEZsX";
export var pyXxl3 = "image-text-block-module--py-xxl-3--sRa5Y";
export var pyXxl4 = "image-text-block-module--py-xxl-4--RKP5B";
export var pyXxl5 = "image-text-block-module--py-xxl-5--uKzn2";
export var ratio = "image-text-block-module--ratio--geQTa";
export var ratio16x9 = "image-text-block-module--ratio-16x9--lBBEJ";
export var ratio1x1 = "image-text-block-module--ratio-1x1--A4y0h";
export var ratio21x9 = "image-text-block-module--ratio-21x9--HFkp4";
export var ratio4x3 = "image-text-block-module--ratio-4x3--xcojB";
export var rounded = "image-text-block-module--rounded---h+aC";
export var rounded0 = "image-text-block-module--rounded-0--0gD0X";
export var rounded1 = "image-text-block-module--rounded-1--xVnhM";
export var rounded2 = "image-text-block-module--rounded-2--BS3WW";
export var rounded3 = "image-text-block-module--rounded-3--H5zGv";
export var roundedBottom = "image-text-block-module--rounded-bottom--Nz4ou";
export var roundedCircle = "image-text-block-module--rounded-circle--iGfoQ";
export var roundedEnd = "image-text-block-module--rounded-end--6KfPw";
export var roundedPill = "image-text-block-module--rounded-pill--9ICxn";
export var roundedStart = "image-text-block-module--rounded-start--wrKgz";
export var roundedTop = "image-text-block-module--rounded-top--2X8vh";
export var shadow = "image-text-block-module--shadow--TpGn6";
export var shadowLg = "image-text-block-module--shadow-lg--y70eU";
export var shadowNone = "image-text-block-module--shadow-none--Jpi3D";
export var shadowSm = "image-text-block-module--shadow-sm--MqDbz";
export var start0 = "image-text-block-module--start-0--BmnX-";
export var start100 = "image-text-block-module--start-100--H1kyD";
export var start50 = "image-text-block-module--start-50--CjrEa";
export var stickyLgTop = "image-text-block-module--sticky-lg-top--uwDEn";
export var stickyMdTop = "image-text-block-module--sticky-md-top--fm6b+";
export var stickySmTop = "image-text-block-module--sticky-sm-top--jldT1";
export var stickyTop = "image-text-block-module--sticky-top--gt77a";
export var stickyXlTop = "image-text-block-module--sticky-xl-top--w8AYs";
export var stickyXxlTop = "image-text-block-module--sticky-xxl-top--CaZM4";
export var stretchedLink = "image-text-block-module--stretched-link--l9aM3";
export var textBlack = "image-text-block-module--text-black--cph5l";
export var textBlack50 = "image-text-block-module--text-black-50--yl0RV";
export var textBody = "image-text-block-module--text-body--ziI36";
export var textBreak = "image-text-block-module--text-break--07xGD";
export var textCapitalize = "image-text-block-module--text-capitalize--OPWsu";
export var textCenter = "image-text-block-module--text-center--gC5d3";
export var textColumn = "image-text-block-module--text-column--Z9Pl2";
export var textDanger = "image-text-block-module--text-danger--dbjkP";
export var textDark = "image-text-block-module--text-dark--ckaN5";
export var textDecorationLineThrough = "image-text-block-module--text-decoration-line-through--XGkBB";
export var textDecorationNone = "image-text-block-module--text-decoration-none--3j51i";
export var textDecorationUnderline = "image-text-block-module--text-decoration-underline--CYgqv";
export var textEnd = "image-text-block-module--text-end--WTxwm";
export var textInfo = "image-text-block-module--text-info--dq50d";
export var textLgCenter = "image-text-block-module--text-lg-center--sjHty";
export var textLgEnd = "image-text-block-module--text-lg-end--WLCZu";
export var textLgStart = "image-text-block-module--text-lg-start--HvbWK";
export var textLight = "image-text-block-module--text-light--4FPZG";
export var textLowercase = "image-text-block-module--text-lowercase--L-nOZ";
export var textMdCenter = "image-text-block-module--text-md-center--PhHh3";
export var textMdEnd = "image-text-block-module--text-md-end--qjZ-C";
export var textMdStart = "image-text-block-module--text-md-start--SxVOK";
export var textMuted = "image-text-block-module--text-muted--z2LDm";
export var textNowrap = "image-text-block-module--text-nowrap--xjxKb";
export var textOpacity100 = "image-text-block-module--text-opacity-100--Y8Fi4";
export var textOpacity25 = "image-text-block-module--text-opacity-25--VkAIc";
export var textOpacity50 = "image-text-block-module--text-opacity-50--0YmMv";
export var textOpacity75 = "image-text-block-module--text-opacity-75--7sTbx";
export var textPrimary = "image-text-block-module--text-primary--WVQsT";
export var textReset = "image-text-block-module--text-reset--5lmQd";
export var textSecondary = "image-text-block-module--text-secondary--WeKSf";
export var textSmCenter = "image-text-block-module--text-sm-center--vLv0F";
export var textSmEnd = "image-text-block-module--text-sm-end--GIZ8j";
export var textSmStart = "image-text-block-module--text-sm-start--My7ld";
export var textStart = "image-text-block-module--text-start--rkeho";
export var textSuccess = "image-text-block-module--text-success--hhoTu";
export var textTruncate = "image-text-block-module--text-truncate--BW6kl";
export var textUppercase = "image-text-block-module--text-uppercase--Jy8jy";
export var textWarning = "image-text-block-module--text-warning--eylts";
export var textWhite = "image-text-block-module--text-white--D+tlJ";
export var textWhite50 = "image-text-block-module--text-white-50--sCiSr";
export var textWrap = "image-text-block-module--text-wrap--pKTUo";
export var textXlCenter = "image-text-block-module--text-xl-center--Z5Cy9";
export var textXlEnd = "image-text-block-module--text-xl-end--Bu4s4";
export var textXlStart = "image-text-block-module--text-xl-start--xA+Q7";
export var textXxlCenter = "image-text-block-module--text-xxl-center--PvxL-";
export var textXxlEnd = "image-text-block-module--text-xxl-end--BTR1a";
export var textXxlStart = "image-text-block-module--text-xxl-start--zq99x";
export var top0 = "image-text-block-module--top-0--JdsTb";
export var top100 = "image-text-block-module--top-100---f9oZ";
export var top50 = "image-text-block-module--top-50--udp5C";
export var translateMiddle = "image-text-block-module--translate-middle--Cz-uE";
export var translateMiddleX = "image-text-block-module--translate-middle-x--Nqxva";
export var translateMiddleY = "image-text-block-module--translate-middle-y--Di7yS";
export var userSelectAll = "image-text-block-module--user-select-all--6vNqc";
export var userSelectAuto = "image-text-block-module--user-select-auto--aopUl";
export var userSelectNone = "image-text-block-module--user-select-none--SOKNT";
export var vh100 = "image-text-block-module--vh-100--hNkbm";
export var visible = "image-text-block-module--visible--uglP5";
export var visuallyHidden = "image-text-block-module--visually-hidden--7yPWW";
export var visuallyHiddenFocusable = "image-text-block-module--visually-hidden-focusable--z+shW";
export var vr = "image-text-block-module--vr--ECzRm";
export var vstack = "image-text-block-module--vstack--m7pHj";
export var vw100 = "image-text-block-module--vw-100--Sni1t";
export var w100 = "image-text-block-module--w-100--pg9Ff";
export var w25 = "image-text-block-module--w-25--8DzUH";
export var w50 = "image-text-block-module--w-50--N5tJ1";
export var w75 = "image-text-block-module--w-75--pbqBK";
export var wAuto = "image-text-block-module--w-auto--guj-1";