import React from "react"
import { graphql } from "gatsby"
import HeaderBlock from "./blocks/header-block"
import ImageTextBlock from "./blocks/image-text-block"
import TableListBlock from "./blocks/table-list-block"
import PortfolioBlock from "./blocks/portfolio-block"

const componentsMap = {
//   STRAPI__COMPONENT_BLOCKS_HEADER_BLOCK: HeaderBlock,
  STRAPI__COMPONENT_BLOCKS_IMAGE_TEXT_BLOCK: ImageTextBlock,
  STRAPI__COMPONENT_BLOCKS_TABLE_LIST_BLOCK: TableListBlock,
  STRAPI__COMPONENT_BLOCKS_PORTFOLIO_BLOCK: PortfolioBlock,
}

const Block = ({ block }) => {
  const Component = componentsMap[block.__typename]

  if (!Component) {
    return null
  }

  return <Component data={block} />
}

const BlocksRenderer = ({ blocks }) => {
  return (
    <React.Fragment>
      {blocks.map((block, index) => (
        <section className={""} key={index}>
          <Block key={`${index}${block.__typename}`} block={block} />
        </section>
      ))}
    </React.Fragment>
  )
}

export const query = graphql`
  fragment RenderBlocks on STRAPI__COMPONENT_BLOCKS_IMAGE_TEXT_BLOCKSTRAPI__COMPONENT_BLOCKS_PORTFOLIO_BLOCKSTRAPI__COMPONENT_BLOCKS_TABLE_LIST_BLOCKUnion {
    __typename
    ... on STRAPI__COMPONENT_BLOCKS_PORTFOLIO_BLOCK {
      Caption
      Header
      projects {
        Name
        Slug
        Cover {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                width: 800

                aspectRatio: 2
                transformOptions: {cropFocus: NORTH, fit: COVER}
              )
            }
          }
        }
        technologies {
          Technology
        }
      }
      Link {
        Label
        URL
      }
    }
    ... on STRAPI__COMPONENT_BLOCKS_TABLE_LIST_BLOCK {
      Caption
      Header
      Education {
        From
        Place
        To
        Type
      }
      Experience {
        From
        Place
        To
        Type
      }
    }
    ... on STRAPI__COMPONENT_BLOCKS_IMAGE_TEXT_BLOCK {
      Caption
      Header
      Order
      Text {
        data {
          Text
        }
      }
      Image {
        localFile {
          childImageSharp {
              gatsbyImageData (
                layout: CONSTRAINED
                width: 800
                aspectRatio: 1
                transformOptions: {cropFocus: CENTER, fit: COVER}
              )
          }
        }
      }
    }
  }
`

export default BlocksRenderer