import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import ReactMarkdown from "react-markdown"

import * as style from "./image-text-block.module.scss"

const ImageTextBlock = ({ data }) => {
  return (
    <div className={style.block}>
      <div className={style.textColumn}>
        <span className={"subheader"}>{data.Caption}</span>
        <h2 className={"header"}>{data.Header}</h2>
        <ReactMarkdown>
          {data.Text.data.Text}
        </ReactMarkdown>
      </div>
      <div className={style.imageColumn}>
        <GatsbyImage image={getImage(data.Image.localFile)} alt={data.Image.alternativeText}/>
      </div>
    </div>
  )
}

export default ImageTextBlock