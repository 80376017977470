import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../layouts"
import BlocksRenderer from "../components/blocks-renderer"
import Seo from "../components/seo"
import ReactMarkdown from "react-markdown"

const Page = ({ data }) => {
  const page = data.strapiPage

//   const seo = {
//     metaTitle: article.title,
//     metaDescription: article.description,
//     shareImage: article.cover,
//   }

  return (
    <main className="mt-8">
      <BlocksRenderer blocks={page.Blocks || []} />
    </main>
  )
}

export const pageQuery = graphql`
  query ($slug: String) {
    strapiPage(Slug: { eq: $slug }) {
      id
      Slug
      Name
      Blocks {
        ...RenderBlocks
      }
    }
  }
`

export default Page