import React from "react"
import ReactMarkdown from "react-markdown"

import * as style from "./table-list-block.module.scss"

const TableListBlock = ({ data }) => {
  return (
    <div className={style.tableList}>
        <span className={"subheader"}>{data.Caption}</span>
        <h2 className={"header"}>{data.Header}</h2>
        {data.Experience &&
          <div className={style.experience}>
            <h3>Experience</h3>
            <table>
              <tbody>
                {data.Experience.map(exp => (
                  <tr>
                    <td>{exp.Type}</td>
                    <td>{exp.Place}</td>
                    <td>{exp.From} - {exp.To}</td>
                  </tr> 
                ))}
              </tbody>
            </table>
          </div>
        }
        {data.Education &&
          <div className={style.education}>
            <h3>Education</h3>
            <table>
              <tbody>
                {data.Education.map(edu => (
                  <tr>
                      <td>{edu.Type}</td>
                      <td>{edu.Place}</td>
                      <td>{edu.From} - {edu.To}</td>
                  </tr> 
                ))}
              </tbody>
            </table>
          </div>
        }
    </div>
  )
}

export default TableListBlock